<template>
    <div
        class="checkox-block"
        :class="validationError && 'checkox-block-error'"
        @click="onClick"
    >
        <div class="checkox-container">
            <!-- <div class="checkbox-icon" v-if="!checked">
                <checkbox-icon />
            </div>
            <div v-if="checked" class="checkbox-icon">
                <checkbox-checked-icon />
            </div> -->
            <input
                :id="check"
                v-model="value"
                type="checkbox"
                class="form-checkbox"
                :disabled="disabled"
                :checked="value"
                @change="onClick"
            >
            <label :for="check">
                <check />
            </label>
        </div>
        <div
            v-if="label"
            class="checkox-block_label"
        >
            {{ label }}
        </div>
    </div>
</template>

<script>
import Check from '@/components/icons/Check.vue';
// import Checkbox from 'mdi-vue/CheckboxBlankOutline.vue';
// import CheckboxChecked from 'mdi-vue/CheckBoxOutline.vue';

export default {
    name: 'FormCheckbox',
    components: {
        Check
        // CheckboxIcon: Checkbox,
        // CheckboxCheckedIcon: CheckboxChecked
    },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {},
        check: {
            type: String
        },
        label: {
            type: String
        },
        errors: {
            type: String
        },
        disabled: {
            type: Boolean
        },
        fixed: {
            type: Boolean
        }
    },
    data() {
        return {
            checked: false
        }
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : '';
        }
    },
    created() {
        this.checked = this.value
    },
    methods: {
        onClick() {
            if (this.fixed) {
                this.checked = true
                this.$emit('input', this.checked)
            } else {
                this.checked = !this.checked
                this.$emit('input', this.checked)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.checkox-block {
    display: flex;
    align-items: center;
    cursor: pointer;
    &_label {
        color: $font-color-main;
        font-weight: normal;
        margin-left: 16px;
        line-height: 20px;
    }
}
.checkox-container {
    height: 30px;
    width: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    // .checkbox-icon {
    //     span {
    //         display: inline-block;
    //         width: 100%;
    //         height: 100%;
    //     }
    // }
    // svg {
    //     color: rgba(0, 0, 0, 0.6);
    // }
    input {
        display: none;

        &:checked + label>svg {
            opacity: 1;
        }
    }

    label {
        display: inline-block;
        position: relative;
        min-width: 30px;
        height: 30px;
        background: $white;
        border: 1px solid #D3D9E2;
        box-sizing: border-box;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;

        >svg {
            position: absolute;
            width: 16px;
            height: 12px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: 0.5s;
        }

    }
}
// .form-checkbox {
//     position: absolute;
//     cursor: pointer;
//     opacity: 0;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     user-select: none;
//     &:checked {
//     }
// }
</style>
