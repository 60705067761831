import routesBase from './routes/base.js'
import routesAuth from './routes/auth.js'
import routesOrder from './routes/order.js'
import routesCheckout from './routes/checkout.js'
import routesProjects from './routes/projects.js'
import routesSupport from './routes/support.js'
import routesBilling from './routes/billing.js'
import routesCredit from './routes/credit.js'
import routesWriters from './routes/writers.js'
import routesResources from './routes/resources.js'
import routesUnsubscribe from './routes/unsubscribe.js'
import routesFeedbackGuest from './routes/feedback-guest.js'
import routesReferral from './routes/referral.js'
import routesSettings from './routes/settings.js'
import routesAffiliate from './routes/affiliate.js'
import routesNotFound from './routes/404.js'
import routesNotifications from './routes/notifications.js'

export const routes = [
    ...routesBase,
    ...routesAuth,
    ...routesOrder,
    ...routesCheckout,
    ...routesProjects,
    ...routesSupport,
    ...routesBilling,
    ...routesCredit,
    ...routesWriters,
    ...routesResources,
    ...routesUnsubscribe,
    ...routesFeedbackGuest,
    ...routesReferral,
    ...routesSettings,
    ...routesAffiliate,
    ...routesNotifications,
    /* routesNotFound should be last !!! */
    ...routesNotFound
]
