import Api from '@/helpers/api/index.js'
import { getCookie, setCookie, deleteCookie } from '@/services/cookie';

export const getReferralHash = () => getCookie('hash_invite')

export const setReferralHash = (hash) => {
    setCookie('hash_invite', hash, { secure: true, 'max-age': 31536000 })
}

export const deleteReferralHash = () => {
    deleteCookie('hash_invite')
}

export const logInvitation = async (payload) => {
    await Api.post('/api/client/friend/log-invitation', payload)
}

export const affiliateSignup = async (payload) => {
    await Api.post('/api/client/affiliate-signup-request', payload)
}

export const affiliateListAvailable = async () => {
    const { data } = await Api.get('/api/client/affiliate-pay-method/list-available')
    return data
}

export const getPaymentsAffiliateListConnected = async () => {
    const { data } = await Api.get('/api/client/affiliate-pay-method/list-connected')
    return data
}

export const deleteWithdrawalMethod = async (payload) => {
    const { data } = await Api.delete('/api/client/affiliate-pay-method/delete', payload)
    return data
}

export const paymentWireCreateOrUpdate = async (payload) => {
    const { data } = await Api.post('/api/client/affiliate-pay-method/wire/create-or-update', payload)
    return data
}

export const paymentUsdtCreateOrUpdate = async (payload) => {
    const { data } = await Api.post('/api/client/affiliate-pay-method/usdt/create-or-update', payload)
    return data
}

export const paymentStripeCreateOrUpdate = async (payload) => {
    const { data } = await Api.post('/api/client/affiliate-pay-method/stripe/create-or-update', payload)
    return data
}

export const paymentPaypalCreateOrUpdate = async (payload) => {
    const { data } = await Api.post('/api/client/affiliate-pay-method/paypal/create-or-update', payload)
    return data
}
