export const RESET = 'RESET'

export const CLEAR_FORM_STANDARD = 'CLEAR_FORM_STANDARD'

export const CLEAR_FORM_EXPRESS = 'CLEAR_FORM_EXPRESS'

export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'

export const SET_ORDER_FORM_DATA = 'SET_ORDER_FORM_DATA'

export const LOADING_SET_STATUS = 'LOADING_SET_STATUS'

export const SET_PRESET = 'SET_PRESET'

export const SET_ORDER_DETAILS_BREADCRUMBS = 'SET_ORDER_DETAILS_BREADCRUMBS'

export const CLEAR_FILES_TO_UPLOAD = 'CLEAR_FILES_TO_UPLOAD'

export const CLEAR_ALL_FILES = 'CLEAR_ALL_FILES'

export const SET_FILES_TO_FORM_DATA = 'SET_FILES_TO_FORM_DATA'

export const UPLOAD_PROGRESS_VALUE = 'UPLOAD_PROGRESS_VALUE'
