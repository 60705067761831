import { cloneDeep } from 'lodash'

import { SchemaOrder } from '@/models/SchemaOrder.js'
import { SchemaExpressOrder } from '@/models/SchemaExpressOrder.js'

export const initialState = {
    batchid: null,
    form_data: new SchemaOrder(),
    express_form_data: new SchemaExpressOrder(),
    files_add: [],
    active_step: 'PriceCalculator',
    loading: {
        status: false,
        message: ''
    },
    selectedPreset: {
        title: 'All',
        count: null
    },
    orderDetailsTitle: {
        typeProject: '',
        titleProject: ''
    },
    uploadProgressValue: 0
}

export const state = cloneDeep(initialState)
