import { getField } from 'vuex-map-fields';

export const getters = {
    getField,
    getterMainClientInfo(state) {
        const {
            firstname,
            lastname,
            email,
            statistics,
            id
        } = state.client_data
        return {
            email,
            firstname,
            lastname,
            loaylty_discount: statistics?.loyalty_discount_rate,
            account_balance: statistics?.account_balance,
            order_total: statistics?.orders_total,
            id
        }
    },
    getterIsAuth(state) {
        return state.auth
    },
    isPhoneOnRecord(state) {
        const {
            phone_code,
            phone
        } = state.client_data
        return phone_code && phone
    },
    isAddressOnRecord(state) {
        const {
            address,
            city,
            country
        } = state.client_data
        return address && city && country
    },
    getAddressDataFields(state) {
        return {
            address: state.client_data.address,
            city: state.client_data.city,
            zip: state.client_data.zip,
            country: state.client_data.country,
            countryFullName: state.client_data.country_nice_name,
            region: state.client_data.region
        }
    },
    getterClientData(state) {
        return state.client_data
    },
    getterPreferredWriters(state) {
        return state.client_data.preferred_writers
    },
    getterBlockedWriters(state) {
        return state.client_data.blocked_writers
    },
    getterPhoneNumber(state) {
        return {
            phone_code: state.client_data.phone_code,
            phone: state.client_data.phone
        }
    },
    getterEmail(state) {
        return state.client_data.email
    },
    getterChkNotifications(state) {
        return {
            chk_sms_notifications: state.client_data.chk_sms_notifications,
            chk_email_notifications: state.client_data.chk_email_notifications,
            chk_email_marketing: state.client_data.chk_email_marketing,
            chk_calls: state.client_data.chk_calls
        }
    },
    getterCallParams(state) {
        return {
            from: state.client_data.call_params.from,
            messengers: state.client_data.call_params.messenger,
            to: state.client_data.call_params.to
        }
    },
    getterSetting(state, getters) {
        return {
            firstname: state.client_data.firstname,
            lastname: state.client_data.lastname,
            email: state.client_data.email,
            phone: getters.getterPhoneNumber,
            preferred_writers: getters.getterPreferredWriters,
            blocked_writers: getters.getterBlockedWriters,
            aff_paymethod: state.client_data.aff_paymethod,
            aff_paypal_email: state.client_data.aff_paypal_email,
            address: { ...getters.getAddressDataFields },
            ...getters.getterChkNotifications
        }
    },
    getterOauthFormData(state) {
        return state.finalizeAuthFormData
    },
    getter2FA(state) {
        return state.client_data.twofa
    },
    getPurchases(state) {
        return state.statistics?.purchases
    },
    getReferralId(state) {
        return state.client_data.referral_hash
    },
    getterStatisticsTotalEarningFriends(state) {
        return state.client_data.statistics.total_earnings_friends
    },
    getterNeedUpdatePhone(state) {
        return state.client_data.required_input?.phone
    },
    getterNeedUpdateEmail(state) {
        return state.client_data.required_input?.email
    },
    getterRegisterSecondStep(state) {
        return state.client_data.required_input?.phone
    },
    getterShowFakeReviews(state) {
        return state.client_data.statistics?.orders_total === 0
    },
    getterUnreadCounters(state) {
        return state.counters
    },
    getterWriterOnline(state) {
        return state.client_data.writers_online_count
    },
    affiliateEmailVerified(state) {
        return state.client_data.affiliate_requirements.is_email_verified
    },
    affiliatePhoneVerified(state) {
        return state.client_data.affiliate_requirements.is_phone_verified
    },
    getterAffiliate(state) {
        return state.client_data.affiliate
    },
    getterFriends(state) {
        return {
            friends: state.client_data.statistics.active_friends,
            orders: state.client_data.statistics.count_batch_friends,
            earnings: state.client_data.statistics.total_earnings_friends
        }
    }
}
