export default [
    // {
    //     routeName: 'orders',
    //     title: 'Dashboard'
    // },
    // {
    //     routeName: 'orders',
    //     title: 'Projects',
    //     page: '1'
    // },
    // {
    //     routeName: 'tickets',
    //     title: 'Help Center',
    //     page: '1'
    // },
    {
        routeName: 'billing',
        title: 'Billing',
        page: '1'
    },
    {
        routeName: 'credits',
        title: 'Credits',
        page: '1'
    },
    {
        routeName: 'friends',
        title: 'Referrals',
        page: '1'
    },
    {
        routeName: 'tickets',
        title: 'Help Center',
        page: '1'
    },
    {
        routeName: 'profile',
        title: 'Settings'
    }
]
