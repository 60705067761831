// calculations Class
import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest, writersListToIdsList } from '@/helpers/utils/'
import { uploadFiles } from '@/services/files'

import {
    ACTION_SET_ACTIVE_STEP,
    ACTION_NEW_ORDER,
    ACTION_EDIT_ORDER,
    ACTION_VALIDATE_DISCOUNT,
    ACTION_CALCULATE_ORDER,
    ACTION_SET_FIRST_ORDER_STEP,
    ACTION_UPLOAD_FILES
} from './action-types.js'

import {
    SET_ACTIVE_STEP,
    CLEAR_FORM_STANDARD,
    SET_ORDER_FORM_DATA,
    LOADING_SET_STATUS,
    CLEAR_FILES_TO_UPLOAD,
    SET_FILES_TO_FORM_DATA,
    UPLOAD_PROGRESS_VALUE
} from './mutation-types.js'

export const actions = {
    [ACTION_SET_ACTIVE_STEP]({ commit }, payload) {
        const orderSteps = ['PriceCalculator', 'AssignmentDetails', 'ExtrasServices']
        const currentStepIndex = orderSteps.findIndex((item) => item === payload.from)

        commit(SET_ACTIVE_STEP, orderSteps[currentStepIndex + payload.step])
    },
    [ACTION_SET_FIRST_ORDER_STEP]({ commit }) {
        const orderSteps = ['PriceCalculator', 'AssignmentDetails', 'ExtrasServices']
        commit(SET_ACTIVE_STEP, orderSteps[0])
    },
    // save order to cart
    async [ACTION_NEW_ORDER]({ commit, dispatch, getters }, payload) {
        if (!getters.getOrderFormData.chk_sources) {
            delete getters.getOrderFormData.sources
        }
        commit(LOADING_SET_STATUS, { message: 'Сreate an order...', status: true })
        const { data } = await Api.post('/api/order/checkout/standard/create-or-update',
            {
                ...removeEmptyParamsFromRequest(getters.getOrderFormData),
                ...payload,
                preferred_writers: writersListToIdsList(getters.getOrderFormData.preferred_writers),
                blocked_writers: writersListToIdsList(getters.getOrderFormData.blocked_writers)
            })
        commit(LOADING_SET_STATUS, { message: 'Uploading files...', status: true })
        await dispatch(ACTION_UPLOAD_FILES, { id: data.id })
        commit(LOADING_SET_STATUS, { message: '', status: false })
        commit(SET_ACTIVE_STEP, 'PriceCalculator')
        commit(CLEAR_FORM_STANDARD)
        return data.action
    },
    async [ACTION_UPLOAD_FILES]({ getters, commit }, payload) {
        let uploadProgressValue = 0
        if (getters.getterFilesAdd.length) {
            commit(LOADING_SET_STATUS, { message: 'Uploading files...', status: true })
            const data = await uploadFiles(getters.getterFilesAdd, payload.id, (e) => {
                uploadProgressValue = (Math.floor((e.loaded * 100) / e.total) - 1);
                commit(UPLOAD_PROGRESS_VALUE, uploadProgressValue)
            })
            commit(LOADING_SET_STATUS, { message: '', status: false })
            commit(CLEAR_FILES_TO_UPLOAD)
            commit(UPLOAD_PROGRESS_VALUE, 0)
            commit(SET_FILES_TO_FORM_DATA, data)
        }
    },
    // edit order and update it in cart
    async [ACTION_EDIT_ORDER]({ commit }, payload) {
        const { data } = await Api.get('/api/order/checkout/standard/fetch', payload)
        commit(SET_ORDER_FORM_DATA, data)
        commit(SET_ACTIVE_STEP, 'PriceCalculator')
        return data
    },
    async [ACTION_CALCULATE_ORDER](ctx, payload) {
        const { data } = await Api.get('/api/order/checkout/standard/quote', {
            ...payload,
            preferred_writers: payload.preferred_writers
        }, {}, false)
        return data
    },
    [ACTION_VALIDATE_DISCOUNT](ctx, payload) {
        const { discount } = payload
        return Api.get('/api/discount/is-valid-for-client', { discount }, { dialog: false })
            .then((response) => {
                const rate = response.data.discount
                if (!rate) throw new Error('discount rate is null')
                return response
            })
            .catch(() => false)
    }
}
